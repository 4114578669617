import React from 'react';
import "./project-icon.scss";

const ProjectIcon = (props) => {

    const logo = document.querySelectorAll('#check');

    for (let i = 0; i < logo.length; i++) {
        console.log(logo[i].getTotalLength());
    }

    if (props.id === 1) {
        return (
            <>
                <svg width="463" height="463" viewBox="0 0 463 463" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M367.5 0H95.5C73.72 0 56 17.72 56 39.5V407.5C56 417.836 62.71 426.628 72 429.766V439.5C72 452.458 82.542 463 95.5 463H367.5C380.458 463 391 452.458 391 439.5V429.766C400.29 426.628 407 417.836 407 407.5V39.5C407 17.72 389.28 0 367.5 0ZM95.5 15H367.5C381.01 15 392 25.991 392 39.5V96H71V39.5C71 25.991 81.99 15 95.5 15ZM367.5 448H95.5C90.813 448 87 444.187 87 439.5V431H376V439.5C376 444.187 372.187 448 367.5 448ZM383.5 416H79.5C74.813 416 71 412.187 71 407.5V111H392V407.5C392 412.187 388.187 416 383.5 416Z"
                        fill="#00588F"/>
                    <path
                        d="M279.5 79C292.458 79 303 68.458 303 55.5C303 42.542 292.458 32 279.5 32C266.542 32 256 42.542 256 55.5C256 68.458 266.542 79 279.5 79ZM279.5 47C284.187 47 288 50.813 288 55.5C288 60.187 284.187 64 279.5 64C274.813 64 271 60.187 271 55.5C271 50.813 274.813 47 279.5 47Z"
                        fill="#00588F"/>
                    <path
                        d="M343.5 79C356.458 79 367 68.458 367 55.5C367 42.542 356.458 32 343.5 32C330.542 32 320 42.542 320 55.5C320 68.458 330.542 79 343.5 79ZM343.5 47C348.187 47 352 50.813 352 55.5C352 60.187 348.187 64 343.5 64C338.813 64 335 60.187 335 55.5C335 50.813 338.813 47 343.5 47Z"
                        fill="#00588F"/>
                    <path
                        d="M111.5 79H215.5C224.047 79 231 72.047 231 63.5V47.5C231 38.953 224.047 32 215.5 32H111.5C102.953 32 96 38.953 96 47.5V63.5C96 72.047 102.953 79 111.5 79ZM111 47.5C111 47.224 111.225 47 111.5 47H215.5C215.775 47 216 47.224 216 47.5V63.5C216 63.776 215.775 64 215.5 64H111.5C111.225 64 111 63.776 111 63.5V47.5Z"
                        fill="#00588F"/>
                    <g id="rotating-part">
                        <path
                            d="M231.5 136C161.196 136 104 193.196 104 263.5C104 333.804 161.196 391 231.5 391C301.804 391 359 333.804 359 263.5C359 193.196 301.804 136 231.5 136ZM231.5 376C169.468 376 119 325.533 119 263.5C119 201.467 169.468 151 231.5 151C293.532 151 344 201.467 344 263.5C344 325.533 293.532 376 231.5 376Z"
                            fill="#00588F"/>
                        <path
                            d="M231.5 168C178.841 168 136 210.841 136 263.5C136 316.159 178.841 359 231.5 359C284.159 359 327 316.159 327 263.5C327 210.841 284.159 168 231.5 168ZM231.5 344C187.112 344 151 307.888 151 263.5C151 219.112 187.112 183 231.5 183C275.888 183 312 219.112 312 263.5C312 307.888 275.888 344 231.5 344Z"
                            fill="#00588F"/>
                        <path
                            d="M231.5 200C227.357 200 224 203.358 224 207.5C224 211.642 227.357 215 231.5 215C258.243 215 280 236.757 280 263.5C280 267.642 283.357 271 287.5 271C291.643 271 295 267.642 295 263.5C295 228.486 266.514 200 231.5 200Z"
                            fill="#00588F"/>
                    </g>
                </svg>
            </>
        );
    }

    if (props.id === 2) {
        return (
            <>
                <g className="ist-icon-wrapper">
                    <svg width="495" height="514" viewBox="0 0 495 514" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="clock">
                            <path
                                d="M261.004 47C389.784 47 494.008 151.212 494.008 280.004C494.008 408.785 389.796 513.008 261.004 513.008C132.222 513.008 28.0001 408.796 28.0001 280.004C28.0001 151.222 132.212 47 261.004 47ZM261.004 483.008C372.941 483.008 464.008 391.941 464.008 280.004C464.008 168.067 372.941 77 261.004 77C149.067 77 58.0001 168.067 58.0001 280.004C58.0001 391.941 149.067 483.008 261.004 483.008Z"
                                fill="#22384F"/>
                            <path
                                d="M261.004 110.028C269.288 110.028 276.004 116.744 276.004 125.028V274.535L335.995 345.86C341.328 352.2 340.511 361.662 334.171 366.995C327.831 372.328 318.367 371.509 313.036 365.171L249.524 289.66C247.251 286.957 246.003 283.537 246.003 280.005V125.028C246.004 116.743 252.719 110.028 261.004 110.028Z"
                                fill="#22384F"/>
                        </g>
                        <rect id="box" x="5" y="5" width="204.878" height="191" rx="37" fill="#526F8E"
                              stroke="#22384F" stroke-width="10"/>
                        <path id="check" d="M42 115L79 157M171.617 55.2969L80.2969 157.168" stroke="#22384F "
                              stroke-width="16" stroke-linecap="round"/>
                    </svg>


                </g>
            </>
        )
    }

    if (props.id === 3) {
        return (
            <svg width="450" height="450" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="microscope">
                    <path
                        d="M107.681 176.175L54.9937 145.762C53.9812 145.181 53.6437 143.906 54.225 142.894L57.2062 137.737C57.7875 136.725 59.0625 136.387 60.075 136.969L112.762 167.381C113.775 167.962 114.112 169.237 113.531 170.25L110.55 175.406C109.969 176.419 108.675 176.756 107.681 176.175Z"
                        fill="#B3C58A"/>
                    <path
                        d="M74.25 179.644C69.225 190.931 79.875 209.681 95.4375 210.281C93.5625 210.356 74.5875 212.1 72.9562 211.163C60.5438 204.038 64.0687 181.631 67.8 177.131L74.25 179.644ZM98.5875 175.425C123.206 195.225 145.987 199.331 162.637 195.694C163.425 195.525 159.544 174.019 162.637 166.406C135.712 184.331 101.25 170.363 101.25 170.363L98.5875 175.425Z"
                        fill="#405608"/>
                    <path
                        d="M104.587 181.35L51.9 150.938C50.8875 150.356 50.55 149.081 51.1312 148.069L54.1125 142.913C54.6937 141.9 55.9687 141.563 56.9812 142.144L109.669 172.556C110.681 173.138 111.019 174.413 110.437 175.425L107.456 180.581C106.875 181.594 105.581 181.931 104.587 181.35ZM90.15 183.469L49.725 170.513C49.5626 170.459 49.391 170.438 49.2205 170.452C49.0499 170.465 48.8838 170.513 48.7319 170.591C48.5799 170.67 48.4452 170.778 48.3356 170.909C48.226 171.041 48.1438 171.193 48.0937 171.356L47.475 173.269L46.05 177.731C45.7125 178.763 46.2937 179.85 47.3062 180.188L86.4937 192.75C87.525 193.088 88.6125 192.506 88.95 191.494L89.925 188.438L90.9937 185.119C91.0477 184.955 91.0686 184.782 91.0553 184.61C91.042 184.439 90.9948 184.271 90.9163 184.118C90.8378 183.964 90.7297 183.828 90.5982 183.716C90.4666 183.605 90.3143 183.521 90.15 183.469Z"
                        fill="#6C900F"/>
                </g>
                <path
                    d="M142.219 74.9062L135.394 83.8687C175.65 104.756 173.194 142.856 154.819 166.969V178.275H183.244C209.025 136.088 189.844 88.0687 142.219 74.9062Z"
                    fill="#6C900F"/>
                <path
                    d="M81.8062 141.15L90.3187 146.044C91.3687 146.644 92.7187 146.325 93.3937 145.312L107.55 126.544L89.9812 116.456L80.9062 138.131C80.3437 139.219 80.7562 140.55 81.8062 141.15Z"
                    fill="#435A06"/>
                <path
                    d="M71.325 121.181L68.5875 112.631C68.25 111.581 68.7938 110.437 69.825 110.044L89.2875 101.025L94.9125 118.65L73.8188 122.587C72.7688 122.85 71.6625 122.25 71.325 121.181Z"
                    fill="#6C900F"/>
                <g id="microscope-objective">
                    <path d="M145.425 12.9469L169.491 26.8406L153.403 54.7031L129.339 40.8094L145.425 12.9469Z"
                          fill="#5E8903"/>
                    <path
                        d="M172.481 29.85L141.694 12.075C140.764 11.5335 140.086 10.6457 139.808 9.60566C139.53 8.56567 139.676 7.45809 140.212 6.52502C141.337 4.59377 143.831 3.91877 145.762 5.04377L176.569 22.8188C178.5 23.9438 179.175 26.4375 178.05 28.3688C177.505 29.3007 176.614 29.9794 175.571 30.2569C174.528 30.5343 173.417 30.3881 172.481 29.85Z"
                        fill="#405608"/>
                </g>
                <path
                    d="M129.037 116.869L87.675 93L118.312 39.9375C119.625 37.6688 122.531 36.8813 124.8 38.1938L157.95 57.3375C160.219 58.65 161.006 61.5563 159.694 63.825L129.037 116.869Z"
                    fill="#78A700"/>
                <path
                    d="M96.45 125.55C107.869 132.15 122.475 128.231 129.075 116.812L87.7125 92.925C81.1125 104.344 85.0312 118.95 96.45 125.55Z"
                    fill="#D5F18E"/>
                <path
                    d="M104.213 145.819L113.494 145.763C114.638 145.763 115.594 144.863 115.65 143.719L118.313 121.688C115.969 118.125 101.756 118.2 99.1688 121.781L102.056 143.794C102.113 144.938 103.069 145.819 104.213 145.819Z"
                    fill="#6C900F"/>
                <path
                    d="M148.106 101.606C157.902 101.606 165.844 93.6649 165.844 83.8688C165.844 74.0726 157.902 66.1313 148.106 66.1313C138.31 66.1313 130.369 74.0726 130.369 83.8688C130.369 93.6649 138.31 101.606 148.106 101.606Z"
                    fill="#D5F18E"/>
                <path
                    d="M148.106 92.25C152.735 92.25 156.487 88.4976 156.487 83.8687C156.487 79.2399 152.735 75.4875 148.106 75.4875C143.477 75.4875 139.725 79.2399 139.725 83.8687C139.725 88.4976 143.477 92.25 148.106 92.25Z"
                    fill="#6C900F"/>
                <path
                    d="M204.75 225.787H35.25C32.9675 225.787 30.7784 224.881 29.1644 223.267C27.5505 221.653 26.6437 219.464 26.6437 217.181V212.531C26.6437 210.249 27.5505 208.06 29.1644 206.446C30.7784 204.832 32.9675 203.925 35.25 203.925H204.75C207.033 203.925 209.222 204.832 210.836 206.446C212.45 208.06 213.356 210.249 213.356 212.531V217.181C213.356 219.464 212.45 221.653 210.836 223.267C209.222 224.881 207.033 225.787 204.75 225.787Z"
                    fill="#6C900F"/>
                <path
                    d="M57.7875 234.562H47.4937C45.4312 234.562 43.7437 232.875 43.7437 230.812V225.787H61.5375V230.812C61.5375 232.875 59.85 234.562 57.7875 234.562ZM192.525 234.562H182.231C180.169 234.562 178.481 232.875 178.481 230.812V225.787H196.275V230.812C196.275 232.875 194.587 234.562 192.525 234.562Z"
                    fill="#405608"/>
                <path
                    d="M73.0875 203.906C94.1812 203.906 117.694 198.656 141.056 183.319C148.5 178.425 160.594 164.625 166.95 165.45C172.125 166.106 176.925 165.825 185.794 183.975C190.031 192.656 197.062 201.713 204.75 203.906C196.275 214.613 80.1187 214.838 73.0875 203.906Z"
                    fill="#6C900F"/>
                <path
                    d="M165.844 197.513C176.468 197.513 185.081 188.9 185.081 178.275C185.081 167.65 176.468 159.038 165.844 159.038C155.219 159.038 146.606 167.65 146.606 178.275C146.606 188.9 155.219 197.513 165.844 197.513Z"
                    fill="#D5F18E"/>
                <path
                    d="M165.938 187.856C171.229 187.856 175.519 183.567 175.519 178.275C175.519 172.983 171.229 168.694 165.938 168.694C160.646 168.694 156.356 172.983 156.356 178.275C156.356 183.567 160.646 187.856 165.938 187.856Z"
                    fill="#6C900F"/>

            </svg>

        )
    }

};

export default ProjectIcon;
