import React from 'react';
import "./projet-card.style.scss"
import "../../index.scss"
import {useNavigate} from "react-router";


const ProjectCard = (props) => {

    const navigate = useNavigate()

    const image = {
        backgroundImage: `url(${props.image})`
    }

    const border = {
        border: `3px solid ${props.themes.light}`,
        borderRadius: "15px"
    }

    const title = {
        color: `${props.themes.light}`
    }

    const button = {
        background: `${props.themes.light}`
    }

    return (
        <div className="project-card">
            <div className="card-wrapper" style={image}>
                <div className="card-title" style={border}>
                    <h3 style={title}>{props.title}</h3>
                    <p>{props.subTitle}</p>
                    <br/>
                    <button className="learn-more" onClick={() => navigate(props.path + "/" + props.title)}>
                        <span className="circle" style={button} aria-hidden="true">
                          <span className="icon arrow"/>
                        </span>
                        <span className="button-text">See more</span>
                    </button>
                </div>
            </div>

            <div className="project-card-small">
                <div className="card-wrapper-small"  style={image}>
                </div>
                <div className="card-title-small" >
                    <h3 style={title}>{props.title}</h3>
                    <p>{props.subTitle}</p>
                    <br/>
                    <button className="learn-more-small" onClick={() => navigate(props.path + "/" + props.title)}>
                        <span className="circle" style={button} aria-hidden="true">
                          <span className="icon arrow"/>
                        </span>
                        <span className="button-text">See more</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
