import React, {useEffect, useState} from "react";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../media-screen-style.scss"
import "./project-page.scss";
import {useParams} from "react-router-dom";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {EffectCoverflow, Keyboard, Mousewheel, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import SmallFooterComponent from "../../component/footer/small-footer.component";
import {useNavigate} from "react-router";
import ProjectIcon from "./project-icons-svg/project-icon";

const data = require("../../projects-json/project-info.json")


const ProjectPage = () => {
    const {cardName} = useParams();

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate("/", {replace: true});
    }

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [])

    /*Error is not relevant, because the === doesn't work on json comparing*/
    const project = data.find((project) => project.title == cardName);

    const theme_light = {
        backgroundColor: `${project.themes.light}`
    }

    const dark_theme = {
        backgroundColor: `${project.themes.dark}`
    }

    const font = {
        fontFamily: `${project.font}`
    }

    const showBtn = project.button;

    const project_background = {
        backgroundImage: `url(${project.image})`
    }

    const team = project.team;

    const type = project.type;

    const tool1 = {
        backgroundImage: `url('../../asses/icon/angular.png')`
    }

    /*Method used to change the number of slides in the slider*/
    let swiperSlides = 2;

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (windowSize.innerWidth < 900) {
        swiperSlides = 1;
    } else {
        swiperSlides = 2;
    }
    /*-------------------------------------------------------------*/

    const ProjectCardsDisplay = () => {

        if (project.title === "IST Working Hours") {
            return (
                <>

                    <div className="project-view">
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={swiperSlides}
                            speed={750}
                            loop={true}
                            pagination={true}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            keyboard={true}
                            mousewheel={true}
                            modules={[EffectCoverflow, Navigation, Keyboard, Mousewheel]}
                            className="my-swiper"
                        >
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image1}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image2}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image3}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image4}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image5}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="website-image"
                                     src={project.websiteImages.image6}
                                     alt="" loading="lazy"/>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </>
            )
        }
    }

    return (
        <div className="project-page-wrapper">

            {/*LOADING PAGE*/}
            <div className="title-wrapper" style={theme_light}>
                <AnimationOnScroll animateIn="animation__animate animate__flipInX" animateOnce={true}>
                    <h1 className="project-title" style={font}>{project.title}</h1>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animation__animate animate__flipInX" animateOnce={true}
                                   delay={500}>
                    <h3 className="project-sub-title">{project.subTitle}</h3>
                </AnimationOnScroll>
            </div>

            {/*FULL PROJECT CONTENT*/}
            <div className="content-wrapper">

                {/*Back navigation*/}
                <div className="back-navigation">

                    <div className="back-icon-div" onClick={navigateBack}>
                        <p>Back</p>
                        <svg width="56" height="43" viewBox="0 0 56 43" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path id="left-1"
                                  d="M14.8946 22.4535L27.1301 34.689L21.2737 40.5453L2.12132 21.3929L21.2737 2.24046L27.1301 8.09679L14.8946 20.3322L13.834 21.3929L14.8946 22.4535Z"
                                  stroke="#CFCFCF" stroke-width="3"/>

                            <path id="left-2"
                                  d="M14.8946 22.4535L27.1301 34.689L21.2737 40.5453L2.12132 21.3929L21.2737 2.24046L27.1301 8.09679L14.8946 20.3322L13.834 21.3929L14.8946 22.4535Z"
                                  stroke="#CFCFCF" stroke-width="3"/>
                            <path
                                id="left-3"
                                d="M14.8946 22.4535L27.1301 34.689L21.2737 40.5453L2.12132 21.3929L21.2737 2.24046L27.1301 8.09679L14.8946 20.3322L13.834 21.3929L14.8946 22.4535Z"
                                stroke={project.themes.light} stroke-width="3"/>

                            <path
                                id="left-4"
                                d="M14.8946 22.4535L27.1301 34.689L21.2737 40.5453L2.12132 21.3929L21.2737 2.24046L27.1301 8.09679L14.8946 20.3322L13.834 21.3929L14.8946 22.4535Z"
                                stroke={project.themes.light} stroke-width="3"/>
                        </svg>
                    </div>

                </div>

                <div className="description-wrapper" style={project_background}/>

                <div className="project-overview-wrapper">

                    <div className="project-description-cards">
                        <div className="overview-description">
                            <AnimationOnScroll animateIn="animation__animate animate__fadeInUp"
                                               animateOnce={true}>
                                <hr style={theme_light}/>
                                <h3 className="problem-title">Overview</h3>
                                <p>{project.description.overview}</p>
                            </AnimationOnScroll>
                        </div>
                    </div>

                    <div className="project-info-wrapper">

                        <div className="project-info">
                            <div className="info">
                                <span className="material-symbols-outlined">groups</span>
                                <p>{team}</p>
                            </div>
                            <hr/>
                            <div className="info">
                                <span className="material-symbols-outlined">timer</span>
                                <div className="time">
                                    <p>{project.timelines.short}</p>
                                    <p>({project.timelines.long})</p>
                                </div>
                            </div>
                            <hr/>
                            <div className="info">
                                <span className="material-symbols-outlined">apps</span>
                                <p>{type}</p>
                            </div>
                        </div>
                        <div className={showBtn === "true" ? "more-btn" : "more-btn-hide"}>
                            <button className="view-more">
                                        <span className="circle" aria-hidden="true"
                                              style={theme_light}>
                                            <span className="icon arrow"/>
                                        </span>
                                <a href={`${project.goToLink}`} target="_blank" className="button-text">Visit
                                    the website</a>
                            </button>
                        </div>

                    </div>
                </div>

                <div className="project-problem" style={theme_light}>

                    <div className="problem-icon">
                        <ProjectIcon {...project}/>
                    </div>

                    <div className="problem-description">
                        <AnimationOnScroll animateIn="animation__animate animate__fadeInUp"
                                           animateOnce={true}>
                            <h3 className="problem-title">Problem</h3>
                            <div className="problem-text">
                                <p>{project.description.problem}</p>
                            </div>

                        </AnimationOnScroll>
                    </div>
                </div>

                <div className="project-solution">
                    <div className="solution-description">
                        <div className="solution-title">
                            <AnimationOnScroll animateIn="animate__animated animate__fadeInLeft"
                                               animateOnce={true}>
                                <div className="project-line-project-page" style={theme_light}/>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__animated animate__fadeInDown"
                                               animateOnce={true}>
                                <h3 className="problem-title">Solution</h3>
                            </AnimationOnScroll>
                            <AnimationOnScroll
                                animateIn="animate__animated animate__fadeInRight"
                                animateOnce={true}>
                                <div className="project-line-project-page" style={theme_light}/>
                            </AnimationOnScroll>
                        </div>
                        <AnimationOnScroll animateIn="animation__animate animate__fadeInUp"
                                           animateOnce={true}>
                            <div className="solution-text">
                                <p>{project.description.solution}</p>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>

                <ProjectCardsDisplay/>

            </div>

            <SmallFooterComponent {...project.themes}/>

        </div>
    )
}

/*Helper function*/
function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export default ProjectPage;
