import React from 'react';
import "./footer.style.scss";

function FooterComponent(props) {
    return (
        <div className='footer'>
            <h2>Let's start a journey together</h2>
            <p>Sends us an email at
                <br/>
                <span><a href="mailto:bump.software@gmail.com"> contact@bump-software.com</a></span>
            </p>
        </div>
    );
}

export default FooterComponent;
