import React, {useEffect} from "react";
import "./home-page.style.scss";
import ProjectCard from "../../component/project-card/project-card.component";
import FooterComponent from "../../component/footer/footer.component";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import "../../media-screen-style.scss"
import NavbarV2 from "../../component/navbar/navbar.component";
// import projects from "../../projects-json/project-info.json";

const projects = require("../../projects-json/project-info.json")


const HomePage = () => {

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [])

    return (
        <div className="wrapper" id="home">

            {/*<NavbarV2/>*/}

            <div className="home-page">

                {/*HOME*/}
                <div className="start-section">
                    <div className="logo-title">
                        <div className="logo-letters-div">
                            <AnimationOnScroll animateIn="animate__fadeInDown"
                                               delay={100}
                                               animateOnce={true}
                                               duration={1.25}>
                                <span className="logo-letter">B</span>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInDown"
                                               delay={100}
                                               animateOnce={true}
                                               duration={1}>
                                <span className="logo-letter">U</span>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInDown"
                                               delay={100}
                                               animateOnce={true}
                                               duration={0.75}>
                                <span className="logo-letter">M</span>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__fadeInDown"
                                               delay={100}
                                               animateOnce={true}
                                               duration={1.5}>
                                <span className="logo-letter">P</span>
                            </AnimationOnScroll>
                        </div>
                        <p>software</p>
                    </div>
                </div>

                <div id="mouse-scroll">
                    <div className="mouse">
                        <div className="mouse-in"/>
                    </div>
                    <div>
                        <span className="down-arrow-1"/>
                        <span className="down-arrow-2"/>
                        <span className="down-arrow-3"/>
                    </div>
                </div>

                <div className="content-wrapper">

                    {/*PROJECTS*/}
                    <div className="projects-section" id="projects">

                        <div className="projects-title">

                            <div className="projects-title-sub-div">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInLeft"
                                                   animateOnce={true}>
                                    <div className="project-line"/>
                                </AnimationOnScroll>
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInDown"
                                                   animateOnce={true}>
                                    <h2>Our Projects</h2>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <div className="project-line"/>
                                </AnimationOnScroll>
                            </div>

                            <div className="sub-title">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInDown"
                                                   animateOnce={true}>
                                    <p>The products we've built with our amazing clients.</p>
                                </AnimationOnScroll>
                            </div>

                        </div>

                        <AnimationOnScroll animateIn="animate__animated animate__fadeIn"
                                           animateOnce={true}>
                            <div className="project-cards">
                                {projects.map(card => <ProjectCard {...card}/>)}
                            </div>
                        </AnimationOnScroll>

                    </div>

                    {/*ABOUT US*/}
                    <div className="about-us-section">

                        <div className="about-us-textual-div">

                            <div className="about-title">
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInLeft animate__animated"
                                    animateOnce={true}>
                                    <div className="project-line"/>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInLeft"
                                    delay="250"
                                    animateOnce={true}>
                                    <h2>About us</h2>
                                </AnimationOnScroll>
                            </div>

                            <div className="about-text">
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <p className="text">
                                        Everyone enjoys working with <span
                                        className="text-logo">BUMP</span>, a studio
                                        that
                                        creates digital products.
                                    </p>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <p className="text">
                                        Our team's special blend of engineers, designers,
                                        strategists, and storytellers
                                        will
                                        support your company's goals and help you turn your concepts
                                        into stunning
                                        digital
                                        products.
                                    </p>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <p className="text">
                                        A crew of experienced and result-driven professionals who do
                                        what they love
                                        and dream big. We tailor our approach based on the project,
                                        requirements, and
                                        client's needs.
                                    </p>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <p className="text">
                                        By creating and implementing software and technology
                                        solutions, we want to boost
                                        the
                                        profitability and effectiveness of your company. Our team
                                        makes sure that the
                                        development process
                                        takes into account all reviews and suggestions.
                                    </p>
                                </AnimationOnScroll>
                                <AnimationOnScroll
                                    animateIn="animate__animated animate__fadeInRight"
                                    animateOnce={true}>
                                    <p className="text">
                                        We support our clients at any stage of the product evolution
                                        - from the idea
                                        stage to
                                        product implementation, support and maintenance.
                                    </p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>

                {/*FOOTER*/}
                <div className="footer-section" id="contact">
                    <FooterComponent/>
                </div>
            </div>


        </div>
    )


}
export default HomePage;


