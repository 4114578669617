import React from 'react';
import "./small-footer.style.scss";

function SmallFooterComponent(props) {


    const footer_background = {
        backgroundColor: `${props.light}`
    }

    return (
        <div className='small-footer' style={footer_background}>
            <div className="footer-content">
                <div className="logo-footer-div">
                    <h3 className="footer-logo">BUMP</h3>
                </div>
                <p>Contact us at
                    <span>
                        <a href="mailto:bump.software@gmail.com">contact@bump-software.com</a>
                    </span>
                </p>

            </div>
        </div>
    );
}

export default SmallFooterComponent;
