import './App.scss';
import {Route, Routes} from "react-router-dom";
import HomePage from "./page/home-page/home-page";
import ProjectPage from "./page/project-page/project-page";

function App() {

  return (

    <>
      <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route exact path="/project/:cardName" element={<ProjectPage/>}/>
      </Routes>
    </>
  );
}

export default App;
